@import '../node_modules/prismjs/themes/prism-tomorrow';

.text-truncate {
  white-space: break-spaces !important;
}

.select__multi-value:nth-child(1) {
  margin-top: 5px !important;
}

.select__multi-value {
  margin-bottom: 5px !important;
}